import $ = require('jquery');
import 'bootstrap';

module App {
    "use strict";
    $(function () {

        initApp();
        $(window).on("resize", onWindowResize);
        onWindowResize(null);
    });


    let pageName: string;
    let $wrapper: JQuery;

    function initApp() {
        $wrapper = $(".mainWrapper");
        pageName = $wrapper.data("page");

        replaceFitImage($wrapper);
        if (pageName == "product") replaceFitImage($(".productHeader"));

        replaceSVGIcon();
        initNav();
        initCarousel();
        initSimilarCarousel();
        initAlertView();
        initDropdown();
        initFileBtn();
        initMultiLineEllipsis();

        $(".loadingView")
            .delay(500)
            .fadeOut(800);
    }

    function onWindowResize(event: JQuery.Event) {
        if (pageName == "influencer-detail") fixGrowCon();
        normalizeSlideHeights();
    }

    function fixGrowCon() {
        if (window.innerWidth >= 1140) {
            let targetH: number = $(".growBase").height();
            $(".growCon").height(targetH);
        } else {
            $(".growCon").height("auto");
        }
    }


    /*
    * Replace img.img-fit @START */
    function replaceFitImage($target: JQuery) {

        let coverTotal: number = $target.find(".img-fit-cover").length;
        for (let i: number = 0; i < coverTotal; i++) {
            let target: JQuery = $target.find(".img-fit-cover:eq(" + i + ")");
            let parent: JQuery = target.parent();
            parent.addClass("has-objectfit");
        }
        let containTotal: number = $target.find(".img-fit-contain").length;
        for (let j: number = 0; j < containTotal; j++) {
            let target: JQuery = $target.find(".img-fit-contain:eq(" + j + ")");
            let parent: JQuery = target.parent();
            parent.addClass("has-objectfit");
        }
        $('.has-objectfit').each(function () {
            let $container = $(this);
            let imgCover = $container.find('img.img-fit-cover').prop('src');
            setBackgroundImg($container, imgCover, 'no-fit-cover');
            let imgContain = $container.find('img.img-fit-contain').prop('src');
            setBackgroundImg($container, imgContain, 'no-fit-contain');
        });
    }

    function setBackgroundImg($container: JQuery, $imgUrl: string, $class: string) {
        if ($imgUrl && $imgUrl.length > 0) {
            $container.css('backgroundImage', 'url(' + $imgUrl + ')').addClass($class);
        }
    }

    /* @END */


    /*
    * Replace SVG-icon @START */
    function replaceSVGIcon() {

        $('img.svg-icon').each((index, element) => {

            let $img = $(element);
            let imgID = $img.attr('id');
            let imgClass = $img.attr('class');
            let imgURL = $img.attr('src');

            $img.load(imgURL, () => {
                let $svg = $img.find('svg');
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }
                $svg = $svg.removeAttr('xmlns:a');
                $img.replaceWith($svg);
            });
        });
    }

    /* @END */


    /*
    * Navigation @START */
    let isTrigger: boolean = false;
    let nav: JQuery;
    let navTrigger: JQuery;

    function initNav() {
        navTrigger = $(".nav-trigger");
        nav = $(".mainNav");

        navTrigger.on("click tap", (event: JQuery.Event) => {
            event.preventDefault();
            isTrigger = !isTrigger;
            if (isTrigger) {
                nav.fadeIn();
                navTrigger.addClass("active");
            } else {
                nav.fadeOut();
                navTrigger.removeClass("active");
            }
        });
    }

    /* @END */


    /*
    * Popup View @START */
    function initAlertView() {
        let $popupView: JQuery = $(".alertView");
        $popupView.find(".closeBtn").on("click tap", (event: JQuery.Event) => {
            event.preventDefault();
            $popupView.fadeOut(500);
        });
    }

    /* @END */


    /*
    * Bootstrap Carousel @START */
    function initCarousel() {

        normalizeSlideHeights();

        let slidinationTotal: number = $(".mainWrapper").find(".carousel.slidination").length;
        for (let i: number = 0; i < slidinationTotal; i++) {
            let carousel: JQuery = $(".mainWrapper").find(".carousel.slidination:eq(" + i + ")");
            let slidination: JQuery = carousel.find(".carousel-slidination");
            let index: string = getDigitsNum(carousel.find(".carousel-item.active").index() + 1);
            let total: string = getDigitsNum(carousel.find(".carousel-item").length);
            slidination.find(".count").html(index);
            slidination.find(".total").html(total);

            carousel.on('slide.bs.carousel', (event: JQuery.Event) => {
                index = getDigitsNum($(event.relatedTarget).index() + 1);
                slidination.find(".count").html(index);
            });
        }
    }

    function getDigitsNum(id: number): string {
        return (id < 10) ? "0" + id : id.toString();
    }

    function initSimilarCarousel(): void {

        let carouselTotal: number = $(".mainWrapper").find(".similar.carousel").length;
        for (let i: number = 0; i < carouselTotal; i++) {

            let indicators: JQuery = $(".mainWrapper").find(".similar.carousel-indicators:eq(" + i + ")");
            indicators.find('li').on("click tap", (event: JQuery.Event) => {
                indicators.find('li.active').removeClass("active");
                $(event.currentTarget).addClass("active");
            });

            let carousel: JQuery = $(".mainWrapper").find(".similar.carousel:eq(" + i + ")");
            let multilineDesc: JQuery = carousel.find(".multi-line-ellipsis");
            if (pageName == "influencer-detail") multiLineEllipsis(multilineDesc, 45);
            if (pageName == "events-detail") multiLineEllipsis(multilineDesc, 14);
            carousel.on('slid.bs.carousel', (event: JQuery.Event) => {
                indicators.find('li.active').removeClass("active");
                let targetId: number = carousel.find(".carousel-item.active").index();
                indicators.find('li[data-slide-to=' + targetId + ']').addClass("active");
            });
        }
    }

    function initMultiLineEllipsis() {
        $(".multi-line-ellipsis").each((index, element) => {
            if ($(element).data("ellipsis") == undefined || $(element).data("ellipsis") == null) return;
            multiLineEllipsis($(element), $(element).data("ellipsis"));
        });
    }

    function multiLineEllipsis(target: JQuery, length: number) {
        target.each((index, element) => {
            if ($.trim($(element).text()).length > length) {
                let t: string = $.trim($(element).text().toString());
                t = t.substring(0, length) + "\n...";
                $(element).text(t);
            }
        });
    }

    function normalizeSlideHeights() {
        $('.carousel').each(function () {
            let items = $('.carousel-item', this);
            items.css('min-height', 0);
            let maxHeight = Math.max.apply(null,
                items.map(function () {
                    return $(this).outerHeight()
                }).get());
            items.css('min-height', maxHeight + 'px');
        })
    }

    /* @END */

    /*
    * Bootstrap Dropdown @START */
    function initDropdown() {

        let dropTotal: number = $(".mainWrapper").find(".dropdown").length;
        console.log('dropTotal', dropTotal);

        for (let i: number = 0; i < dropTotal; i++) {
            let $dropdown: JQuery = $(".mainWrapper").find(".dropdown:eq(" + i + ")");
            let $toggle: JQuery = $dropdown.find(".dropdown-toggle");
            let $dropmenu: JQuery = $dropdown.find(".dropdown-menu");
            $dropmenu.on('click', 'a', (event: JQuery.Event) => {
                event.preventDefault();
                $toggle.text($(event.currentTarget).text());
                $toggle.val($(event.currentTarget).text());
            });
        }
    }

    /* @END */

    /*
    * Input[type=file] @START */
    function initFileBtn() {
        let btnTotal: number = $wrapper.find(".fileBtn").length;
        for (let i: number = 0; i < btnTotal; i++) {

            let btn: JQuery = $(".mainWrapper").find(".fileBtn:eq(" + i + ")");
            let fileInput: any = btn.find("input[type=file]");
            let previewImg: JQuery = btn.find(".preview").find(".cover");

            fileInput.change((event: JQuery.Event) => {

                btn.addClass("active");
                // @ts-ignore
                let files: any = event.currentTarget["files"];
                let reader = new FileReader();
                reader.onload = (event: any) => {
                    previewImg.attr('src', event.target.result);
                    // previewImg.css("opacity", 1);
                    let parent: JQuery = previewImg.parent();
                    parent.addClass("has-objectfit");
                    setBackgroundImg(parent, previewImg.prop('src'), 'no-fit-cover');
                };
                reader.readAsDataURL(files[0]);
            });
        }
    }
    /* @END */
}